@tailwind base;
@tailwind components;
@tailwind utilities;


input:focus, button:focus {
    outline: none;
}

input{
    -webkit-appearance: none;
    outline: none;
}

select{
    -webkit-appearance: none;
    outline: none;
}

html {
    scroll-behavior: smooth;
  }